import "./styles.css";

import { useState } from "react";
import { send } from "@emailjs/browser";

import Navbar from "../../components/Navbar";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [message, setMessage] = useState("");

  const [spinner, setSpinner] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setSpinner(true);

    let template = {
      to_name: "Henrique R.",
      from_name: name,
      message: message,
      reply_to: email,
      user_tel: telephone,
    };

    send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, template, process.env.REACT_APP_USER_ID).then(
      (response) => {
        setName("");
        setEmail("");
        setTelephone("");
        setMessage("");
        setSpinner(false);

        alert("Recebi seu email! Obrigado.");
      },
      (err) => {
        console.log(err);
        setSpinner(false);
        window.open(`mailto:henriquerodriguesmatteus@gmail.com?subject=Contato+Email&body=${message} + "tel: "${telephone}`);
      }
    );
  }

  return (
    <>
      <Navbar />

      <div className="mt-5">
        <div>
          <h4>Formulário de contato</h4>
          <p className="text-nocolor">Preencha o formulário para entrar em contato</p>
        </div>

        <div id="form-wrapper">
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
              <div className="flex-grow-1 me-2">
                <div className="form-group">
                  <label htmlFor="name">Nome</label>
                  <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="form-group">
                  <label htmlFor="telephone">Telefone</label>
                  <input type="tel" className="form-control" id="telephone" value={telephone} onChange={(e) => setTelephone(e.target.value)} />
                </div>
              </div>
              <div className="flex-grow-1">
                <div className="form-group">
                  <label htmlFor="message">Digite sua mensagem</label>
                  <textarea className="form-control" id="message" rows={6} value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
                </div>
              </div>
            </div>

            <div className="mt-3 d-flex">
              <button type="submit" className="btn btn-primary">
                Enviar
              </button>
              {spinner === true && <div className="ms-2 spinner-border text-primary" role="status"></div>}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Contact;
