import { Link } from "react-router-dom";
import "./styles.css";

function Navbar() {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <span className="hoverNavButtons">Henrique R.</span>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  <span className="hoverNavButtons">HOME</span>
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link active" href="https://github.com/henriquetty" rel="noreferrer" target="_blank">
                  <span className="hoverNavButtons">GITHUB</span>
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" to="/contact">
                  <span className="hoverNavButtons">CONTATO</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
