import "./styles.css";
import Navbar from "../../components/Navbar";

function Homepage() {
  return (
    <>
      <Navbar />

      <div className="mt-5">
        <p className="text-center fw-bold fs-2">Website em construção</p>
        <button className="btn btn-info p-3 mt-5 d-block mx-auto">
          <a className="text-decoration-none text-light fw-bold" href="mailto:henriquerodriguesmatteus@gmail.com?subject=Contato+Email">
            Me envie um e-mail
          </a>
        </button>
      </div>
    </>
  );
}

export default Homepage;
